import {createTheme} from '@mui/material';
import './fonts';

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    borderColor: string;
  }

  interface Palette {
    borderColor: string;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontSize: '2.25rem',
      fontFamily: 'Exo_2'
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      fontFamily: 'Exo_2'
    },
    h3: {
      fontSize: '1.75rem',
      fontFamily: 'Exo_2'
    },
    h4: {
      fontSize: '1.5rem',
      fontFamily: 'Exo_2'
    },
    h5: {
      fontSize: '1.25rem'
    },
    h6: {
      fontSize: '1.25rem'
    }
  },
  palette: {
    primary: {
      main: '#FF0000'
    },
    secondary: {
      main: '#6E6E6E'
    },
    grey: {
      50: '#E8E8E8',
      100: '#E8E8E8',
      200: '#E8E8E8',
      300: '#E8E8E8',
      400: '#E8E8E8',
      500: '#6E6E6E'
    },
    borderColor: 'rgba(0,0,0,0.23)'
  }
});

export default theme;
