import {ReactElement, useState} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {Box, Container, Divider, Grid, IconButton} from '@mui/material';
import Header from './components/Header';
import Menu from './components/Menu';
import TopBar from './components/TopBar';
import logo from './images/logo-pemsa.svg';
import './styles.css';

export default function PemsaHeader(): ReactElement {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  function handleMenuIconButtonClick() {
    setIsMenuOpened((i) => !i);
  }

  return (
    <>
      <div className="contenedor-cabecera">
      <Container>
        <Grid container component="header">
          <Grid item xs={12} md={3}>
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <a href="https://www.pemsa-rejiband.com" title="Pemsa"><img src={logo} className="logo" alt="Pemsa" fetch-priority="high" />
              </a>
              <IconButton
                onClick={handleMenuIconButtonClick}
                sx={{
                  display: {
                    xs: 'inline-block',
                    md: 'none'
                  }
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Menu isMenuOpened={isMenuOpened} />
          </Grid>
        </Grid>
      </Container>
      </div>

      <Divider />

      <Container sx={{mb: 4}}>
        <Header />
      </Container>
    </>
  );
}
