import {ReactElement} from 'react';
import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import ListItem from './ListItem';

type Props = {
  isMenuOpened: boolean;
};

export default function Menu({isMenuOpened}: Props): ReactElement {
  const {t} = useTranslation(['menu']);
  return (
    <Box
      sx={{
        display: {
          xs: isMenuOpened ? 'block' : 'none',
          md: 'block'
        },
        p: {
          xs: 2,
          md: 0
        },
        borderTopColor: 'primary.main',
        borderTopWidth: {
          xs: 4,
          md: 0
        },
        borderTopStyle: 'solid',
        boxShadow: {
          xs: 1,
          md: 0
        }
      }}
    >
      <ul id="menu-menu-principal-espanol" className="nav nav-menu">
        <ListItem text={t('menu:onlineCatalogue.label')} href={t('menu:onlineCatalogue.link')} />
        <ListItem text={t('menu:products.label')}>
          <ul className="sub-menu">
            <li>
              <a href={t('menu:rejiband.link')}>{t('menu:rejiband.label')}</a>
            </li>
            <li>
              <a href={t('menu:pemsaband.link')}>{t('menu:pemsaband.label')}</a>
            </li>
            <li>
              <a href={t('menu:megaband.link')}>{t('menu:megaband.label')}</a>
            </li>
            <li>
              <a href={t('menu:metatray.link')}>{t('menu:metatray.label')}</a>
            </li>
            <li>
              <a href={t('menu:tubosRigidos.link')}>{t('menu:tubosRigidos.label')}</a>
            </li>
            <li>
              <a href={t('menu:tubosFlexibles.link')}>{t('menu:tubosFlexibles.label')}</a>
            </li>
            <li>
              <a href={t('menu:prensaestopas.link')}>{t('menu:prensaestopas.label')}</a>
            </li>
            <li>
              <a href={t('menu:soportes.link')}>{t('menu:soportes.label')}</a>
            </li>
          </ul>
        </ListItem>
         <ListItem text={t('menu:soluciones.label')}>
          <ul className="sub-menu">
            <li>
              <a href={t('menu:dataCenter.link')}>{t('menu:dataCenter.label')}</a>
            </li>
            <li>
              <a href={t('menu:renovables.link')}>{t('menu:renovables.label')}</a>
            </li>
            <li>
              <a href={t('menu:industriaAlimentaria.link')}>{t('menu:industriaAlimentaria.label')}</a>
            </li>
            <li>
              <a href={t('menu:industriaPetroquimica.link')}>{t('menu:industriaPetroquimica.label')}</a>
            </li>
            <li>
              <a href={t('menu:industriaOem.link')}>{t('menu:industriaOem.label')}</a>
            </li>
            <li>
              <a href={t('menu:terciario.link')}>{t('menu:terciario.label')}</a>
            </li>
            <li>
              <a href={t('menu:tuneles.link')}>{t('menu:tuneles.label')}</a>
            </li>
            <li>
              <a href={t('menu:vehiculoElec.link')}>{t('menu:vehiculoElec.label')}</a>
            </li>
            <li>
              <a href={t('menu:industriaGeneral.link')}>{t('menu:industriaGeneral.label')}</a>
            </li>
            <li>
              <a href={t('menu:industriaAgua.link')}>{t('menu:industriaAgua.label')}</a>
            </li>
          </ul>
        </ListItem>
        <ListItem text={t('menu:company.label')}>
          <ul className="sub-menu">
            <li>
              <a href={t('menu:quienesSomos.link')}>{t('menu:quienesSomos.label')}</a>
            </li>
            <li>
              <a href={t('menu:sostenibilidad.link')}>{t('menu:sostenibilidad.label')}</a>
            </li>
            <li>
              <a href={t('menu:calidad.link')}>
                {t('menu:calidad.label')}
              </a>
            </li>
            <li>
              <a href={t('menu:trabaja.link')}>
                {t('menu:trabaja.label')}
              </a>
            </li>
            <li>
              <a href={t('menu:contacto.link')}>{t('menu:contacto.label')}</a>
            </li>
            <li>
              <a href={t('menu:noticias.link')}>{t('menu:noticias.label')}</a>
            </li>
          </ul>
        </ListItem>
        <ListItem text={t('menu:expertOne.label')}>
          <ul className="sub-menu">
            <li>
              <a href={t('menu:catalogos.link')}>{t('menu:catalogos.label')}</a>
            </li>
            <li>
              <a href={t('menu:tarifas.link')}>{t('menu:tarifas.label')}</a>
            </li>
            <li>
              <a href={t('menu:certificados.link')}>{t('menu:certificados.label')}</a>
            </li>
            <li>
              <a href={t('menu:documentacion.link')}>{t('menu:documentacion.label')}</a>
            </li>
            <li>
              <a href={t('menu:videoBlog.link')}>{t('menu:videoBlog.label')}</a>
            </li>
            <li>
              <a href={t('menu:app.link')}>{t('menu:app.label')}</a>
            </li>
            <li>
              <a href={t('menu:bim.link')}>{t('menu:bim.label')}</a>
            </li>
            <li>
              <a href={t('menu:partidas.link')}>{t('menu:partidas.label')}</a>
            </li>
          </ul>
        </ListItem>
        <ListItem text={t('menu:pemsaPro.label')} href={t('menu:pemsaPro.link')} />
      </ul>
    </Box>
  );
}
